.reportReservationsList {
    width: 100%;
}

.reportReservationsList .headerBox {
    display: flex;
    margin: 1% 0% 2% 2%;
}

.reportReservationsList .headertypo {
    font-size: 1.3rem;
}

.reportReservationsList .occExtraMainBlock {
    display:  flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem;
    margin: -2% 1% 1% 1%;
}

.reportReservationsList .occExtraMainBlock .individualBlock {
    flex: 1;
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.reportReservationsList .occExtraMainBlock .individualBlock .dataInfo {
    color: #888888;
    margin: 0%;
}

.reportReservationsList .reservationTableHeadingBox {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    justify-content: space-between;
    align-items: center;
    margin: 0 2% 2% 2%;
    padding: 2%;
    background-color: #eaeef5;
    border-radius: 10px;
    height: 1%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.reportReservationsList .display6Box {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: space-between;
    align-items: center;
    margin: 0 2% 2% 2%;
    padding: 2%;
    background-color: #eaeef5;
    border-radius: 10px;
    height: 1%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.reportReservationsList .display8Box {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    justify-content: space-between;
    align-items: center;
    margin: 0 2% 2% 2%;
    padding: 2%;
    background-color: #eaeef5;
    border-radius: 10px;
    height: 1%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.reportReservationsList .display5Box {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-content: space-between;
    align-items: center;
    margin: 0 2% 2% 2%;
    padding: 1%;
    background-color: #eaeef5;
    border-radius: 10px;
    height: 1%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.reportReservationsList .display7Box {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    justify-content: space-between;
    align-items: center;
    margin: 0 2% 2% 2%;
    padding: 1%;
    background-color: #eaeef5;
    border-radius: 10px;
    height: 1%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.reportReservationsList .tableBox {
    margin: 2%;
}

.reportReservationsList .tableBox .reservationRows {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    align-items: center;
    padding: 2%;
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 10px;
    overflow-x: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.reportReservationsList .tableBox .display6Rows {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    align-items: center;
    padding: 2%;
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 10px;
    overflow-x: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.reportReservationsList .tableBox .display8Rows {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    align-items: center;
    padding: 2%;
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 10px;
    overflow-x: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.reportReservationsList .tableBox .display5Rows {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    padding: 1.5%;
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 10px;
    overflow-x: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} 

.reportReservationsList .tableBox .display7Rows {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    align-items: center;
    padding: 1.5%;
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 10px;
    overflow-x: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} 

.reportReservationsList .pagination {
    margin-top: 20px;
    text-align: center;
}

.reportReservationsList .pagination .paginationButton {
    background-color: transparent !important;
    color: green;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 100%;
    padding: 0px;
    min-width: 25px;
    margin: 0px 7px
}


.reportReservationsList .pagination .activePage {
    background-color: #2dcd7a !important;
    color: white !important
}

.reportReservationsList .pagination .previousButton {
    background-color: transparent;
    color: green;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 100%;
    padding: 0px;
    min-width: 25px
}

.reportReservationsList .pagination .nextButton {
    background-color: transparent;
    color: green;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 100%;
    padding: 0px;
    min-width: 25px
}
.settingsPage {
    width: 82%;
}
@media screen and (max-width:1280px) {
    .settingsPage {
        width: 100%;
    }
    .paymentConfig {
        width: 90%;
        margin: auto;
    }
}

.settingsPage .infoBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1%;
    background-color: #ffffff;
    margin: 2%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.settingsPage .infoBarTypo {
    font-size: 1.2rem;
}

.settingsPage .settingsBox {
    height: auto !important;
    display: flex;
    align-items: center;
    margin-left: 2%;
}

.settingsPage .settingsBox .settingsTypo {
    font-size: 2rem;
    text-align: left;
    margin: 2% 0 0 3%
}

.settingsPage .settingsFieldsTypo {
    font-size: 1rem;
    text-align: left;
    margin-bottom: 5px;
}

.settingsPage .settingsButtons .viewButtons {
    background-color: #2dcd7a;
    color: #000000;
    height: 75%;
    padding: 1%;
    /* border-radius: 10px; */
    text-transform: capitalize
}

.settingsPage .settingsButtons .viewButtons:hover {
    background-color: #b6d7a8ff;
}

.settingsPage .viewBox {
    max-height: 100% !important;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%
}

.settingsPage .viewBox .tableBox .viewButtons {
    margin: 0 5px;
}

.settingsPage .viewBox .tableCell {
    border: 1px solid #efefef;
}

.settingsPage .viewBox .viewButtons {
    background-color: #FDECEB;
    color: #DB3737;
    height: 75%;
    border-radius: 5px;
    margin: 0 5% 0 0;
    text-transform: capitalize
}

.settingsPage .viewBox .viewButtons:hover {
    background-color: #FDECEB;
}

/* Email */
.email-settings-section {
    margin-bottom: 20px;
}

.settings-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.setting-row {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 3% 0 0 0;
    justify-content: center;
}

.settingsTypo {
    font-size: 1.2rem;
}


/* payment Configuration */
.paymentConfig {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.row {
    display: flex;
    gap: 10px;
}

label {
    display: flex;
    align-items: center;
    gap: 15px;
}

.reportsList .paymentOptionWrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-left: 2%;
}

.reportsList .paymentOption {
    padding: 8px 8px;
    margin: 10px 0px;
    border: 1px solid #2dcd7a;
    margin-right: 5px;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
}

.reportsList .activePaymentOption {
    background-color: #2dcd7a;
    color: #fff;
}

.settingList .settingOptionWrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 3%;
}

.settingList .settingOption {
    padding: 8px 8px;
    margin: 10px 0px;
    border: 1px solid #2dcd7a;
    margin-right: 5px;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
}

.settingList .activeSettingOption {
    background-color: #2dcd7a;
    color: #fff;
}

textarea {
    padding: 16px 14px;
    border-radius: 4px;
    width: 100%;
    outline: none;
    background-color: transparent;
    font-size: 16px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
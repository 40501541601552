.guestsList {
    width: 82%;
}

@media screen and (max-width:1280px) {
    .guestsList {
        width: 100%;
    }
}

.guestsList .infoBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1%;
    background-color: #ffffff;
    /* margin: 2%; */
    margin-right: -1%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.guestsList .infoBarTypo {
    font-size: 1.2rem;
}

.guestsList .box .transactionstypography{
    font-size: 2rem;
    margin-left: 10%;
}

.guestsList .box .button {
    background-color: #2dcd7a;
    color: #ffffff;
    height: 75%;
    padding: 1%;
    border-radius: 20px;
}

.guestsList .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 3% 2% 3%;
    margin-right: -4%;
}

.guestsList .tableheadingbox {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    align-items: center;
    margin: 0 2%;
    margin-right: -1%;
    padding: 1% 0 2% 0;
    background-color: #eaeef5;
    border-radius: 10px;
    height: 1%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.guestsList .tableheadingbox .headerTypo {
    font-size: 1rem;
}

.guestsList .tableBox {
    margin: 2%;
}

.guestsList .tableBox .rows {
    display: grid;
    grid-template-columns: repeat(7, 1fr); 
    align-items: center;
    padding: 1%;
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 10px;
    overflow-x: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-right: -3.5%;
}

.guestsList .pagination {
    margin-top: 20px;
    text-align: center;
}
.guestsList .pagination .paginationButton {
    background-color: transparent !important;
    color: green;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 100%;
    padding: 0px;
    min-width: 25px;
    margin: 0px 7px
}


.guestsList .pagination .activePage {
    background-color: #2dcd7a !important;
    color: white !important
}

.guestsList .pagination .previousButton {
    background-color: transparent;
    color: green;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 100%;
    padding: 0px;
    min-width: 25px
}

.guestsList .pagination .nextButton {
    background-color: transparent;
    color: green;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 100%;
    padding: 0px;
    min-width: 25px
}

.transactionsListBox {
    display: flex;
    justify-content: flex-end;
}

.guestsList .transactionsListBox .searchBox {
    margin-right: 10%;
    margin-bottom: 7%;
}
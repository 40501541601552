.root {
    background-color: #f8f8f8;
    display: flex;
    width: calc(100% - 1em);
    height: 100%;
    margin: 0 1em;
    min-height: 100dvh;
}

/* Sidebar */
.sidebar {
    /* background-color: #FFFFFF; */
    background-color: #E1F5E6;
    width: 15%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 5px 0 5px -5px rgba(159, 159, 159, 0.5);
    padding: 0 0 0 2%;
    margin-left: -1em;
}

.sidebar .button {
    /* font-size: 1.2rem; */
    font-size: 1.1rem;
    margin-left: -3%;
    width: 100%;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    padding: 8px 16px;
    text-align: left;
    box-sizing: border-box;
    border: none;
    background: transparent;
}

.sidebar .button:hover {
    color: white;
    background-image: linear-gradient(45deg, #22d4ca, #1eba7a);
}

.sidebar .typography {
    margin-top: 20%;
    color: #000000;
    /* font-size: 0.9rem; */
    font-size: 1.2rem;
}

.sidebar .loginImage {
    width: 100%;
    height: auto;
    margin-left: -8%;
    margin-bottom: -5%;
}

.sidebar .button .buttonImage {
    margin-right: 8px;
    width: 24px;
    height: 24px;
}

.sidebar .link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    width: 100%;
}

.sidebar .extramargin {
    margin-bottom: 56%;
}

@media screen and (max-width:1280px) {
    .sidebar{
        padding: 0px;
        width: 64px;
    }
    .loginImage{
        margin-bottom: 20px;
    }
    .sidebar .button .linksLabel {
        display: none;
    }
    .sidebar .typography{
        display: none;
    }
    .sidebar .button{
        padding: 8px 10px;
        display: flex;
        justify-content: center !important;
        margin-left: 0px;
    }
    .sidebar .button a{
        justify-content: center !important;
    }
    .sidebar .button .buttonImage{
        margin: 0px !important;
    }
}
.remoteReservationList {
    width: 100%;
    text-align: left;
}

.remoteReservationList .infoBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1%;
    background-color: #ffffff;
    margin: 2%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.remoteReservationList .infoBox .blackoutButton {
    background-color: #2dcd7a;
    color: #000000;
    text-transform: capitalize;
    white-space: nowrap;
}

.remoteReservationList .remoteResFormBox .remoteReservationstypography {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 30px;
}

.remoteReservationList .remoteResBox {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding: 2% 1% 2.5% 1%;
    background-color: #ffffff;
    border-radius: 10px;
    height: 50%;
    box-shadow: 0px 3px 5px rgba(159, 159, 159, 255);
    max-width: 500px;
    margin: 7% auto;
}

.remoteBlackoutDates{
    width: 40%;
    margin: auto;
}

.remoteReservationList .remoteResBox .remoteResLeft {
    width: 100%;
    margin-bottom: 20px;
}

.remoteReservationList .remoteResBox .remoteResMiddle {
    width: 100%;
    margin-bottom: 20px;
}

.remoteReservationList .remoteResBox .remoteResRight {
    width: 100%;
    margin-bottom: 20px;
}

.remoteReservationList .remoteResButton {
    width: 100%;
    background-color: #2dcd7a;
    color: #000000;
    text-transform: capitalize
}

.remoteReservationList .remoteResButton:hover {
    background-color: #2dcd7a;
    color: #000000;
}

@media screen and (max-width:1024px) {
    .remoteReservationList .remoteResBox {
        padding: 20px 10px;
    }
    .remoteBlackoutDates{
        width: 100%;
        padding: 20px 10px;
        box-sizing: border-box;
    }
    .remoteReservationList .infoBox{
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        padding: 10px 10px !important;
        text-align: center;
    }
    .remoteReservationList .infoBox .blackoutButton {
        margin-top: 10px;
    }
}
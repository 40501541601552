.transactionsList {
    width: 82%;
}
@media screen and (max-width:1280px) {
    .transactionsList {
        width: 100%;
    }
}

.transactionsList .infoBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1%;
    background-color: #ffffff;
    margin: 2%;
    margin-right: -1%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.transactionsList .infoBarTypo {
    font-size: 1.2rem;
}

.transactionsList .box .transactionstypography {
    font-size: 2rem;
}

.transactionsList .box .button {
    background-color: #2dcd7a;
    color: #ffffff;
    height: 75%;
    padding: 1%;
    border-radius: 20px;
}

.transactionsList .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 3% 2% 3%;
    margin-right: -4%;
}

.transactionsList .tableheadingbox {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    align-items: center;
    margin: 0 2%;
    padding: 1% 0 2% 0;
    background-color: #eaeef5;
    border-radius: 10px;
    height: 2%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-right: -1%;
}

.transactionsList .tableheadingbox .headerTypo {
    font-size: 1rem;
}

.transactionsList .tableBox {
    margin: 2%;
}

.transactionsList .tableBox .rows {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    align-items: center;
    padding: 1%;
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 10px;
    overflow-x: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-right: -4%;
}

.transactionsList .pagination {
    margin-top: 20px;
    text-align: center;
}
.transactionsList .pagination .paginationButton {
    background-color: transparent !important;
    color: green;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 100%;
    padding: 0px;
    min-width: 25px;
    margin: 0px 7px
}


.transactionsList .pagination .activePage {
    background-color: #2dcd7a !important;
    color: white !important
}

.transactionsList .pagination .previousButton {
    background-color: transparent;
    color: green;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 100%;
    padding: 0px;
    min-width: 25px
}

.transactionsList .pagination .nextButton {
    background-color: transparent;
    color: green;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 100%;
    padding: 0px;
    min-width: 25px
}

.transactionsListBox {
    display: flex;
    justify-content: flex-end;
}

.transactionsList .transactionsListBox .searchBox {
    margin-right: 10%;
    margin-bottom: 7%;
}

/* print page */
.transactionsList .printBox {
    margin: 2% 0;
    padding: 2%;
    border: 1px solid black;
    width: 65%;
    text-align: left;
}

.transactionsList .mainHeading {
    font-size: 2rem;
}

.transactionsList .printLine {
    width: 90%;
}

.transactionsList .detailsHeading {
    font-size: 1.5rem;
    margin: 2% 0 2% 7%;
}

.transactionsList .detailsTypo {
    font-size: 1.3rem;
}

.transactionsList .detailsGrid {
    margin-left: 10%;
}

.transactionsList .detailsItemXs4 {
    flex: 0 0 33.3333%; 
    max-width: 33.3333%;
}

.transactionsList .detailsItemXs8 {
    flex: 0 0 66.6667%; 
    max-width: 66.6667%;
}
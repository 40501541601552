.settingsPage {
    width: 82%;
}
@media screen and (max-width:1280px) {
    .settingsPage {
        width: 94%;
    }
}

.infoBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1%;
    background-color: #ffffff;
    margin: 2%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.infoBarTypo {
    font-size: 1.2rem;
}

.viewButtons {
    background-color: #2dcd7a;
    color: #ffffff;
    height: 75%;
    padding: 1%;
    border-radius: 10px;
}

.viewButtons:hover {
    background-color: #39c17b;
}

.settingsPage .viewBox {
    max-height: 700px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* height: 100% */
}

.viewButtons {
    margin: 0 5px;
}

.tableCell {
    border: 1px solid #2dcd7a;
}  

.settingsPage .settingsBox {
    height: 5%;
    display: flex;
    align-items: center;
    margin-left: 2%;
}

.settingsPage .settingsBox .buttonImage {
    margin-right: 8px; 
    width: 35px; 
    height: 35px; 
}
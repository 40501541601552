.loginRoot {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.leftPane {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rightPane {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
}

.loginContent {
    text-align: center;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loginContent .signInTypo {
    font-size: 2rem;
    margin-bottom: 1.5rem;
}

.logo {
    margin-bottom: 1.5rem;
    height: auto;
}

.loginImage {
    width: 100%;
    height: 100vh; 
    object-fit: cover; 
    margin: 0; 
    padding: 0; 
}

.inputField {
    margin-bottom: 1rem;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inputField .typo {
    margin-bottom: 0.5rem;
    text-align: left;
    width: 100%;
}

.forgotPasswordDiv {
    width: 22%;
    justify-content: left;
    align-self: flex-end;
    margin-right: 20%;
}

.forgotPasswordTypo {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
}

.buttonField {
    margin-bottom: 1rem;
    width: 30%;
}

.buttonField .signInButton {
    background-color: #2dcd7a;
    color: #ffffff;
    border-radius: 10px;
    margin-top: 1rem;
}

.buttonField .signInButton:hover {
    background-color: #39c17b;
}

.signUp {
    margin-bottom: 1rem;
    margin-left: 3%;
    width: 60%;
    flex-direction: column;
    align-items: center;
}

.signIn {
    margin-bottom: 1rem;
    width: 21%;
    flex-direction: column;
    align-items: center;
}

.passwordRules {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 7%;
}
.remoteSpaceSelect .remoteSpaceSelectBox {
    height: 5%;
}

.remoteSpaceSelect .remoteSpaceSelectBox .remoteSpaceSelectTypo {
    font-size: 2rem;
    text-align: left;
    margin-left: 3%;
}

.remoteSpaceSelect .numberofresultsbox .typography {
    font-size: 1.5rem;
    text-align: left;
    padding: 2% 0 3% 3%;
}

.remoteSpaceSelect .scrollContainer {
    max-height: 570px;
    overflow-y: auto;
}

.remoteSpaceSelect .RemoteSpaceSelectBox {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    align-items: center;
    margin: 0 2% 2% 2%;
    padding: 1% 1% 1% 1%;
    background-color: #eaeef5;
    border-radius: 10px;
    height: 1%;
}

.remoteSpaceSelect .spaceHeadingBox .headerTypo {
    font-size: 1rem;
}

.remoteSpaceSelect .spacetableBox {
    margin-left: 2%;
}

.remoteSpaceSelect .spacetableBox .rows {
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr 1fr 1.5fr 1fr 1fr 1.5fr .5fr;
    align-items: center;
    padding: 2%;
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 10px;
}

.remoteSearchWrapper {
    gap: 64px;
}

.searchForm .MuiFormControlLabel-root {
    gap: 0px !important;
    margin-right: 20px
}

.searchForm .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
}

.searchForm .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
    color: rgba(0, 0, 0, 0.6);
}

.searchForm .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #2dcd7a;
    border-width: 2px;
}

.searchForm .MuiFormLabel-root.Mui-focused {
    color: #2dcd7a;
}


.searchForm .MuiMenuItem-root {
    background-color: transparent !important;
}

.searchForm .moreFilter {
    background-color: transparent !important;
}

.searchForm .MuiMenu-paper {
    top: 86px !important;
}

.advanceFilter {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-bottom: 20px
}


@media screen and (max-width:1024px) {
    .remoteSearchWrapper {
        gap: 16px !important;
    }

    .searchForm .searchFormDate {
        width: 100% !important;
    }

    .advanceFilter {
        grid-template-columns: 1fr !important;
        gap: 10px !important;
    }

    .css-19kzrtu {
        padding: 0px !important;
    }

    .tsHeading {
        font-size: 18px !important;
    }

}
.guest-detail .btn {
    display: flex;
    justify-content: flex-end;
    margin: -35px 4% -2% 0;
}

.guest-detail .btn .buttn {
    margin-right: -4.5%;
    background-color: rgb(153, 153, 153);
    color: #ffffff;
}

.guest-detail .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
    text-align: left;
}

.guest-detail .reservation-details {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.guest-detail .imageFlexBoxBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.guest-detail .imageFlexBoxStart {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.guest-detail .imageFlexBoxStart img {
    margin-right: 10px;
}

.guest-detail .resButton {
    background-color: #2dcd7a;
    color: #FFF;
    text-transform: capitalize;
}

.guest-detail .resButton:hover {
    background-color: #2dcd7a;
}

.guest-detail .current-reservation,
.guest-detail .past-reservations {
    margin-bottom: 20px;
}

.guest-detail h2 {
    font-weight: 600;
    margin-bottom: 10px;
}

.guest-detail .reservation-info,
.guest-detail .extras,
.guest-detail .payment-info {
    display: flex;
    flex-wrap: wrap;
}

.guest-detail .payment-info {
    gap: 16px;
}

.guest-detail .reserved {
    background-color: rgb(153, 153, 153);
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 14px;
    margin: 0px;
    line-height: 0px;
}

.guest-detail .reservation-dates {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    font-size: 14px;
    text-align: center;
}

.guest-detail .reservation-dates span {
    display: flex;
    align-items: center;
    margin-right: 30px;
    margin-bottom: 10px;
}

.guest-detail .reservation-dates img {
    width: 15px;
    margin-right: 5px;
}

.guest-detail .discount {
    color: #6c757d;
    font-size: 0.85em;
}

.guest-detail .btn-record-payment {
    background-color: #28a745;
    color: white;
    margin-top: 10px;
}

.guest-detail aside {
    width: 25%;
}

.guest-detail .customer-info,
.guest-detail .vehicle-details,
.guest-detail .private-notes {
    background-color: #fff;
    padding: 20px;
    padding-top: 5px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.guest-detail .customer-info .form-row {
    display: flex;
    justify-content: left;
    margin: 3% 4% 3% 0
}

.guest-detail .customer-info .form-row-2 {
    display: flex;
    justify-content: left;
    margin-bottom: 20px;
    margin-right: 4%;
}

.guest-detail .customer-info .form-row-3 {
    display: flex;
    justify-content: left;
    /* margin-bottom: 5%; */
    margin-right: 4%;
}

.guest-detail .customer-info .form-row .field {
    width: 40%;
}

.guest-detail .customer-info .form-row-2 .full-width {
    width: 83%;
}

.guest-detail .customer-info .form-row-3 .left {
    width: 30%;
    margin-bottom: 3%;
}

.guest-detail .customer-info .form-row-3 .middle {
    margin-left: 3%;
    width: 30%;
}

.guest-detail .customer-info .form-row-3 .right {
    margin-left: 3%;
    width: 17%;
}

.guest-detail .customer-info h3,
.guest-detail .vehicle-details h3,
.guest-detail .private-notes h3 {
    border-bottom: 1px solid lightgray;
    padding-bottom: 5px;
}

.guest-detail .vehicle-details .fields {
    font-size: 14px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px 10px;
    padding: 10px;
    border-radius: 6px;
}

.guest-detail .vehicle-details .fields p {
    margin: 0px;
    margin-bottom: 10px;
}

.guest-detail .vehicle-details .fields input {
    width: 80%;
    padding: 5px;
    border-radius: 6px;
    outline: none;
    border: 1px solid lightgray
}


.guest-detail .reservationTableHeadingBox {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: space-between;
    align-items: center;
    margin: 0 0% 2% 0%;
    padding: 1% 1% 1% 1%;
    background-color: #eaeef5;
    border-radius: 10px;
    height: 1%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.guest-detail .tableheadingbox .headerTypo {
    font-size: 1rem;
}

.guest-detail .tableBox {
    margin: 2% 0%;
}

.guest-detail .tableBox .reservationRows {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    align-items: center;
    padding: 1%;
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 10px;
    overflow-x: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width:1024px) {
    .guest-detail aside {
        width: 29%;
    }
}
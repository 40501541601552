.reservationList {
    width: 82%;
}

@media screen and (max-width:1280px) {
    .reservationList {
        width: 100%;
    }
}

.reservationList .infoBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1%;
    background-color: #ffffff;
    margin: 2%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.reservationList .infoBarTypo {
    font-size: 1.2rem;
}

.reservationList .box .reservationstypography {
    font-size: 2rem;
}

.reservationList .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2% 3%;
    margin-right: -4%;
}

.reservationList .box .buttonGroup {
    display: flex;
    gap: 10px;
    width: 40%;
    align-items: center;
}

.reservationList .searchBox {
    max-width: 20%;
    margin: 0% 0 0% 2%;
}

.reservationList .box .button {
    background-color: #ffffff;
    color: #2dcd7a;
    border: 1.5px solid #2dcd7a;
    padding: 2%;
    border-radius: 20px;
    text-transform: capitalize;
}

.reservationList .box .button:hover {
    background-color: #39c17b;
    color: #ffffff;
    border-color: #39c17b;
}

.reservationList .reservationTableHeadingBox {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    justify-content: space-between;
    align-items: center;
    margin: 0 2% 2% 2%;
    padding: 1% 1% 4% 1%;
    background-color: #eaeef5;
    border-radius: 10px;
    height: 1%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-right: -1%;
}

.reservationList .tableheadingbox .headerTypo {
    font-size: 1rem;
}

.reservationList .tableBox {
    margin: 2%;
}

.reservationList .tableBox .reservationRows {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    align-items: center;
    padding: 1%;
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 10px;
    overflow-x: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-right: -3%;
}

.reservationList .pagination {
    margin-top: 20px;
    text-align: center;
}

.reservationList .pagination .paginationButton {
    background-color: transparent !important;
    color: green;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 100%;
    padding: 0px;
    min-width: 25px;
    margin: 0px 7px
}


.reservationList .pagination .activePage {
    background-color: #2dcd7a !important;
    color: white !important
}

.reservationList .pagination .previousButton {
    background-color: transparent;
    color: green;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 100%;
    padding: 0px;
    min-width: 25px
}

.reservationList .pagination .nextButton {
    background-color: transparent;
    color: green;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 100%;
    padding: 0px;
    min-width: 25px
}

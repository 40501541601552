.view {
    margin-right: -2%;
}

.view .box .reservationstypography {
    font-size: 2rem;
    text-align: left;
    margin: 3% 0 0 0;
}

.view .main {
    display: flex;
    flex-direction: row;
}

.view .main .leftRow {
    width: 70%;
}

.view .main .rightRow {
    width: 40%;
}

.view .userDetails,
.view .internalNotes {
    background-color: #ffffff;
    box-shadow: 5px 5px 5px -5px rgba(159, 159, 159, 255);
    margin-left: 3%;
}

.view .typo {
    font-size: 1.5rem;
    text-align: left;
    padding: 4% 0 0 3%;
    margin-left: 1%;
}

.view .horizontalLine {
    width: 93%;
    margin-left: 3%;
    margin-bottom: 3%;
    border: 1px solid rgba(159, 159, 159, 255);
}

.view .userDetails .form-row {
    display: flex;
    justify-content: left;
    margin-bottom: 20px;
    margin-right: 4%;
}

.view .userDetails .form-row-2 {
    display: flex;
    justify-content: left;
    margin-bottom: 20px;
    margin-right: 4%;
}

.view .userDetails .form-row-3 {
    display: flex;
    justify-content: left;
    margin-bottom: 5%;
    margin-right: 4%;
}

.view .userDetails .form-row .field {
    width: 40%;
    margin-left: 3%;
}

.view .userDetails .form-row-2 .full-width {
    width: 83%;
    margin-left: 3%;
}

.view .userDetails .form-row-3 .left {
    margin-left: 3%;
    width: 30%;
    margin-bottom: 3%;
}

.view .userDetails .form-row-3 .middle {
    margin-left: 3%;
    width: 30%;
}

.view .userDetails .form-row-3 .right {
    margin-left: 3%;
    width: 17%;
}

/* addOns */
.view .addOns {
    background-color: #ffffff;
    box-shadow: 5px 5px 5px -5px rgba(159, 159, 159, 255);
    margin-left: 3%;
    margin-top: 3%;
    padding-bottom: 1%;
}

.view .addOnsForm {
    margin: 0 4% 4% 4%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
}

.view .addOnsForm .textfields {
    width: 70%;
}

.view .addOnsForm .ab {
    background-color: #2dcd7a;
    color: #000000;
    border-radius: 10px;
    width: 70%;
    text-transform: capitalize;
}

/* payment */
.view .payment {
    background-color: #ffffff;
    box-shadow: 5px 5px 5px -5px rgba(159, 159, 159, 255);
    margin-left: 3%;
    margin-top: 3%;
    margin-bottom: 3%;
}

.view .payment .typo {
    font-size: 1.5rem;
}

.view .payment .paymentRadio {
    margin-left: 4%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.view .payment .rows {
    display: flex;
    /* align-items: ; */
    justify-content: space-between;
    /* margin-bottom: 10px;  */
    margin: 2% 40% 2% 5%;
}

.view .payment .headings {
    font-size: 1.3rem;
}

.view .payment .cardDetailsBox {
    /* background-color: #f0fcf4; */
    border-radius: 30px;
    margin: 2%;
}

.view .payment .cardDetailsBox .row {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 2%;
}

.view .payment .cardDetailsBox .row .typo {
    text-align: left;
    font-size: 1rem;
    padding-left: 0px !important;
    padding-top: 0px !important;
}

.view .payment .cardDetailsBox .resRight {
    margin-left: 2%;
}

/* .view .payment .rows .date {
    width: 48%;
} */

.view .payment .cardDetailsBox .expiryInputRow {
    display: flex;
    flex-direction: column;
    margin-left: 3%;
}

.view .payment .cardDetailsBox .expiryInputRow .typo {
    font-size: 1.1rem;
    margin-bottom: 12px;
    margin-left: 0px !important;
    padding-left: 0px !important;
    padding-top: 0px !important;
}

.view .payment .cardDetailsBox .expiryInputRow .expiryInput {
    width: 40%;
    margin-right: 1rem;
}

.view .payment .cardDetailsBox .expiryInputRow .expiryInputYY {
    width: 4rem;
}

.view .payment .cardDetailsBox .cvvInput {
    width: 20%;
    margin-right: 30%;
}

.view .payment .ab {
    background-color: #2dcd7a;
    color: #000000;
    border-radius: 10px;
    width: 20%;
    margin-bottom: 3%;
    text-transform: capitalize;
}

/* booking details */
.view .bookingDetails {
    background-color: #ffffff;
    box-shadow: 5px 5px 5px -5px rgba(159, 159, 159, 255);
    margin-left: 5%;
    margin-bottom: 7%;
}

.view .bookingDetails .bookingTypo {
    font-size: 1.5rem;
    text-align: left;
    padding: 4% 0 0 3%;
    margin-left: 2%;
}

.view .bookingDetails .bookingRow {
    display: flex;
    justify-content: space-between;
    padding: 1% 3%;
}

.view .bookingDetails .bookingLabel {
    font-size: 1.2rem;
    text-align: left;
    margin-left: 2%;
}

.view .bookingDetails .bookingValue {
    font-size: 1.2rem;
    text-align: right;
    margin-right: 2%;
}

.view .actionButtons {
    display: flex;
    width: 80%;
}

.view .actionButtons .button,
.view .userDetails .editSaveButton .view .internalNotes .saveEditButton {
    background-color: #2dcd7a;
    color: #000000;
    border-radius: 10px;
    width: 20%;
    margin: 0 0 3% 3%;
    text-transform: capitalize;
}

.custom-disabled .MuiInputBase-input.Mui-disabled {
    color: black !important;
    -webkit-text-fill-color: black !important;
    opacity: 1;
}

.custom-disabled-textArea {
    color: black !important;
    background-color: #ffffff !important;
    -webkit-text-fill-color: black !important;
    opacity: 100;
}

@media screen and (max-width:1024px) {
    .view .actionButtons {
        width: 100%;
    }
}
.reservationList .infoBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1%;
    background-color: #ffffff;
    margin: 2%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-right: -1%;
}

.reservationList .infoBarTypo {
    font-size: 1.2rem;
}

/* form */
.reservationList {
    width: 82%;
}
@media screen and (max-width:1280px) {
    .reservationList {
        width: 100%;
    }
}

.reservationList .cancel {
    background-color: #fdeceb;
    color: #db3737;
    text-transform: capitalize;
    margin-right: 2%;
}

.reservationList .cancel:hover {
    background-color: #fdeceb;
}

.reservationList .formBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: -3%;
}

.reservationList .formBox .reservationstypography {
    font-size: 2rem;
    text-align: left;
    margin-left: 3%;
    margin-top: 2%;
}

.reservationList .resBox {
    width: 60%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px 40px;
}

@media screen and (max-width:1024px) {
    .reservationList .resBox {
        width: 80%;
    }
}

.reservationList .resBox .resLeft {
    width: 100%;
}

.reservationList .resBox .resMiddle {
    width: 100%;
}

.reservationList .resBox .resRight {
    width: 100%;
}

.reservationList .resBox .resButton {
    width: 100%;
    background-color: #2dcd7a;
    color: #000000;
    text-transform: capitalize;
    height: 40px;
}

/* select site  */
.reservationList .selectsitebox2 .reservationstypography {
    font-size: 2rem;
}

.reservationList .selectsitebox2 .typography {
    font-size: 1.5rem;
    justify-self: center;
}

.reservationList .numberofresultsbox {
    margin-top: -3%;
}

.reservationList .numberofresultsbox .typography {
    font-size: 1.2rem;
    text-align: left;
    padding: 2% 0 3% 3%;
}

.reservationList .scrollContainer {
    max-height: 570px;
    overflow-y: auto;
    margin-right: -2%;
}

.reservationList .spaceHeadingBox {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    align-items: center;
    margin: 0 2% 2% 2%;
    padding: 1% 1% 2.5% 1%;
    background-color: #eaeef5;
    border-radius: 10px;
    height: 1%;
    margin-right: -1%;
}

.reservationList .spaceHeadingBox .headerTypo {
    font-size: 1rem;
}

.reservationList .spacetableBox {
    margin-left: 2%;
}

.reservationList .spacetableBox .rows {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    align-items: center;
    padding: 2%;
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 10px;
}

/* user details */
.reservationList .scrollContainerUserDetails {
    max-height: 800px;
    overflow-y: auto;
    margin-right: -2%;
}

.reservationList .main {
    display: flex;
    flex-direction: row;
}

.reservationList .main .leftRow {
    width: 65%;
}

.reservationList .main .rightRow {
    width: 33%;
}

.reservationList .userDetails,
.reservationList .internalNotes {
    background-color: #ffffff;
    box-shadow: 5px 5px 5px -5px rgba(159, 159, 159, 255);
    margin-left: 3%;
}

.reservationList .typo {
    font-size: 1.5rem;
    text-align: left;
    padding: 4% 0 0 3%;
    margin-left: 1%;
}

.reservationList .horizontalLine {
    width: 93%;
    margin-left: 3%;
    margin-bottom: 3%;
    border: 1px solid rgba(159, 159, 159, 255);
}

.reservationList .userDetails .form-row {
    display: flex;
    justify-content: left;
    margin-bottom: 20px;
    margin-right: 4%;
}

.reservationList .userDetails .form-row-2 {
    display: flex;
    justify-content: left;
    margin-bottom: 20px;
    margin-right: 4%;
}

.reservationList .userDetails .form-row-3 {
    display: flex;
    justify-content: left;
    margin-bottom: 5%;
    margin-right: 4%;
}

.reservationList .userDetails .form-row .field {
    width: 40%;
    margin-left: 3%;
}

.reservationList .userDetails .form-row-2 .full-width {
    width: 83%;
    margin-left: 3%;
}

.reservationList .userDetails .form-row-3 .left {
    margin-left: 3%;
    width: 30%;
    margin-bottom: 3%;
}

.reservationList .userDetails .form-row-3 .middle {
    margin-left: 3%;
    width: 30%;
}

.reservationList .userDetails .form-row-3 .right {
    margin-left: 3%;
    width: 17%;
}

/* addOns */
.reservationList .addOns {
    background-color: #ffffff;
    box-shadow: 5px 5px 5px -5px rgba(159, 159, 159, 255);
    margin-left: 3%;
    margin-top: 3%;
    padding-bottom: 1%;
}

.reservationList .addOnsForm {
    margin: 0 4% 4% 4%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
    align-items: center;
}

.reservationList .addOnsForm .textfields {
    width: 70%;
}

.reservationList .addOnsForm .addButton {
    background-color: #2dcd7a;
    color: #fff;
    border-radius: 10px;
    width: 70%;
    text-transform: capitalize;
}

/* payment */
.reservationList .payment {
    background-color: #ffffff;
    box-shadow: 5px 5px 5px -5px rgba(159, 159, 159, 255);
    margin-left: 3%;
    margin-top: 3%;
    margin-bottom: 3%;
}

.reservationList .payment .typo {
    font-size: 1.5rem;
}

.reservationList .payment .paymentRadio {
    margin-left: 4%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reservationList .payment .paymentOptionWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-left: 4%;
    margin-bottom: 30px;
}

.reservationList .payment .paymentOption {
    padding: 8px 10px;
    border: 1px solid #2dcd7a;
    margin-right: 10px;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
}

.reservationList .payment .activePaymentOption {
    background-color: #2dcd7a;
    color: #fff;
}

.reservationList .payment .rows {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 2% 40% 2% 5%;
}

.reservationList .payment .headings {
    text-align: left;
    margin-bottom: 12px;
    font-size: 1.1rem;
}

.reservationList .payment .cardDetailsBox {
    border-radius: 30px;
    margin: 2%;
}

.reservationList .payment .cardDetailsBox .row {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 2%;
}

.reservationList .payment .cardDetailsBox .row .typo {
    text-align: left;
    font-size: 1rem;
    padding-left: 0px !important;
    padding-top: 0px !important;
}

.reservationList .payment .cardDetailsBox .resRight {
    margin-left: 2%;
    /* width: 100%; */
}

.reservationList .payment .cardDetailsBox .expiryInputRow {
    display: flex;
    flex-direction: column;
    margin-left: 3%;
}

.reservationList .payment .cardDetailsBox .expiryInputRow .typo {
    font-size: 1.1rem;
    margin-bottom: 12px;
    margin-left: 0px !important;
    padding-left: 0px !important;
    padding-top: 0px !important;
}

.reservationList .payment .cardDetailsBox .expiryInputRow .expiryInput {
    width: 40%;
    margin-right: 1rem;
}

.reservationList .payment .cardDetailsBox .expiryInputRow .expiryInputYY {
    width: 4rem;
}

.reservationList .payment .cardDetailsBox .cvvInput {
    width: 20%;
    margin-right: 30%;
}

.reservationList .payment .date {
    width: 49%;
}

.reservationList .payment .addButton {
    background-color: #2dcd7a;
    color: #000000;
    border-radius: 10px;
    width: 20%;
    /* margin-left: -70%; */
    margin-bottom: 3%;
    text-transform: capitalize;
}

/* booking details */
.reservationList .bookingDetails {
    background-color: #ffffff;
    box-shadow: 5px 5px 5px -5px rgba(159, 159, 159, 255);
    margin-left: 5%;
}

.reservationList .bookingDetails .bookingTypo {
    font-size: 1.5rem;
    text-align: left;
    padding: 4% 0 0 3%;
    margin-left: 2%;
}

.reservationList .bookingDetails .bookingRow {
    display: flex;
    justify-content: space-between;
    padding: 1% 3%;
}

.reservationList .bookingDetails .bookingRow div {
    display: flex;
    align-items: center;
    width: 50%;
}

.reservationList .bookingDetails .bookingRow svg {
    margin-right: 5px;
    margin-left: 2%;
}

.reservationList .bookingDetails .checkout {
    display: flex;
    justify-content: end;
}

.reservationList .bookingDetails .bookingLabel {
    font-size: 1rem;
    text-align: left;
    margin-left: 2%;
}

.reservationList .bookingDetails .bookingValue {
    font-size: 1rem;
    text-align: right;
    margin-right: 2%;
}
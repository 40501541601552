.siteAvailability {
    width: 82%;
}
@media screen and (max-width:1280px) {
    .siteAvailability {
        width: 94%;
    }
}

.siteAvailability .infoBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1%;
    background-color: #ffffff;
    margin: 2% 0 2% 2%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.siteAvailability .infoBarTypo {
    font-size: 1.2rem;
}

.siteAvailability .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2% 3%;
    margin-top: -2%;
    margin-right: -3%;
}

.siteAvailability .divStyle {
    display: flex;
    align-items: center;
    width: 30%;
    margin-left: -1%;
}

.siteAvailability .box .siteAvailabilitytypography {
    font-size: 2rem;
    margin-left: 4%;
}

.siteAvailability .tableContainer {
    overflow-x: auto;
    overflow-y: auto;
    margin: 2% 0;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-left: 2%;
    margin-top: -0.5%;
    height: 850px;
}

.siteAvailability .dateCell {
    width: 100px;
    text-align: center;
    height: 40px;
    padding-left: 0px;
    padding-right: 0px;

}

.siteAvailability td{
    border-bottom: 0px;
    border-right: 1px solid #E2E8F0;
}

.siteAvailability .dateCell span {
    display: inline-block;
    width: calc(100% - 10px);
    text-align: center;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 10px;
}

.siteAvailability .stickyHeader {
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 10px;
    width: 100px;
    text-align: center;
    background-color: white;
}

.siteAvailability .stickyFirstColumn {
    position: sticky;
    left: 0;
    z-index: 2;
    padding: 10px;
    text-align: center;
    background-color: white;
}

.siteAvailability .stickySecondColumn {
    position: sticky;
    left: 78px;
    z-index: 2;
    padding: 10px;
    text-align: center;
    background-color: white;
}

.siteAvailability .stickyThirdColumn {
    position: sticky;
    left: 128px;
    z-index: 2;
    padding: 10px;
    text-align: center;
    background-color: white;
}

.siteAvailability .boardTimeTab{
    background-color: #2DCD7A33;
    padding: 2px;
    border-radius: 1000px;
    height: 40px;
    color: black;
    font-size: 12px;
    display: flex;
    align-items: center
}
.siteAvailability .tableContainer select{
    background-color: #2DCD7A33;
    height: 44px;
    padding: 0px 16px;
    border: 0px;
    border-radius: 1000px;
    outline: none;
    cursor: pointer;
}
.siteAvailability .boardTimeTab div{
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.siteAvailability .activeTab{
    border: 1px solid #000000;
    border-radius: 1000px;
    background-color: white;
    height: 100%;
}
.archivedList {
    width: 82%;
    margin: 0 auto;
    box-sizing: border-box;
}

@media screen and (max-width:1280px) {
    .archivedList {
        width: 100%;
    }
}

.archivedList .infoBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1%;
    background-color: #ffffff;
    margin: 2%;
    margin-right: -0.2%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.archivedList .infoBarTypo {
    font-size: 1.2rem;
}

.archivedList .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 3% 2% 3%;
    margin-right: -3%;
}

.archivedList .box .buttonImage {
    margin-right: 8px;
    width: 35px;
    height: 35px;
}

.archivedList .box .buttonGroup {
    display: flex;
    gap: 10px;
    width: 25%;
}

.archivedList .searchBox {
    max-width: 20%;
    margin: 0 0 2% 2%;
}

.archivedList .transactionstypography {
    font-size: 2rem;
}

.archivedList .button {
    background-color: #ffffff;
    color: #2dcd7a;
    border: 1.5px solid #2dcd7a;
    padding: 2%;
    border-radius: 20px;
    text-transform: capitalize;
}

.archivedList .box .button:hover {
    background-color: #39c17b;
}

.archivedList .tableheadingbox {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    align-items: center;
    margin: 0 2%;
    padding: 1% 0;
    background-color: #eaeef5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-right: -0.2%;
}

.archivedList .headerTypo {
    font-size: 1rem;
    text-align: center;
}

.archivedList .tableBox {
    margin: 2% 0 0 2%;
    overflow-x: auto;
}

.archivedList .table {
    width: 100%;
    border-collapse: collapse;
}

.archivedList .rows {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    align-items: center;
    padding: 1%;
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.archivedList .pagination {
    margin-top: 20px;
    text-align: center;
}

.archivedList .pagination .paginationButton {
    background-color: transparent !important;
    color: green;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 100%;
    padding: 0px;
    min-width: 25px;
    margin: 0px 7px
}


.archivedList .pagination .activePage {
    background-color: #2dcd7a !important;
    color: white !important
}

.archivedList .pagination .previousButton {
    background-color: transparent;
    color: green;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 100%;
    padding: 0px;
    min-width: 25px
}

.archivedList .pagination .nextButton {
    background-color: transparent;
    color: green;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 100%;
    padding: 0px;
    min-width: 25px
}

.transactionsListBox {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
}

.transactionsListBox .searchBox {
    margin-right: 10%;
}
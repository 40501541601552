.remoteReservationList .box .reservationstypography {
    font-size: 2rem;
    text-align: left;
    display: flex;
    align-items: center;
    margin: 3% 0 0 4%;
    white-space: nowrap;
}

.remoteReservationList .main {
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;
}

.remoteReservationList .main .leftRow {
    width: 70%;
}

.remoteReservationList .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2% 3%;
}

.remoteReservationList .main .rightRow {
    width: 40%;
}

.remoteReservationList .userDetails {
    background-color: #ffffff;
    box-shadow: 0px 0px 5px rgba(159, 159, 159, 1);
    margin-left: 3%;
}

.remoteReservationList .typo {
    font-size: 1.5rem;
    text-align: left;
    padding: 4% 0 0 3%;
    margin-left: 1%;
}

.remoteReservationList .horizontalLine {
    width: 93%;
    margin-left: 3%;
    margin-bottom: 3%;
    border: 1px solid rgba(159, 159, 159, 255);
}

.remoteReservationList .userDetails .form-row {
    display: flex;
    justify-content: left;
    margin-bottom: 20px;
    margin-right: 4%;
}

.remoteReservationList .userDetails .form-row-2 {
    display: flex;
    justify-content: left;
    margin-bottom: 20px;
    margin-right: 4%;
}

.remoteReservationList .userDetails .form-row-3 {
    display: flex;
    justify-content: left;
    margin-bottom: 5%;
    margin-right: 4%;
}

.remoteReservationList .userDetails .form-row .field {
    width: 40%;
    margin-left: 3%;
}

.remoteReservationList .userDetails .form-row-2 .full-width {
    width: 83%;
    margin-left: 3%;
}

.remoteReservationList .userDetails .form-row-3 .left {
    margin-left: 3%;
    width: 30%;
    margin-bottom: 3%;
}

.remoteReservationList .userDetails .form-row-3 .middle {
    margin-left: 3%;
    width: 30%;
}

.remoteReservationList .userDetails .form-row-3 .right {
    margin-left: 3%;
    width: 17%;
}

/* addOns */
.remoteReservationList .addOns {
    background-color: #ffffff;
    box-shadow: 0px 0px 5px rgba(159, 159, 159, 1);
    margin-left: 3%;
    margin-top: 3%;
    padding-bottom: 1%;
}

.remoteReservationList .addOnsForm {
    margin: 0 4% 4% 4%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
}

.remoteReservationList .addOnsForm .textfields {
    width: 70%;
}

.remoteReservationList .addOnsForm .addButton {
    background-color: #2dcd7a;
    color: #000000;
    border-radius: 10px;
    width: 70%;
    text-transform: capitalize
}

/* payment */
.remoteReservationList .payment {
    background-color: #ffffff;
    box-shadow: 0px 0px 5px rgba(159, 159, 159, 1);
    margin-left: 3%;
    margin-top: 3%;
    margin-bottom: 3%;
}

.remoteReservationList .payment .typo {
    font-size: 1.5rem;
}

.remoteReservationList .payment .paymentRadio {
    margin-left: 4%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.remoteReservationList .payment .rows {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 2% 40% 2% 5%;
}

.remoteReservationList .payment .headings {
    text-align: left;
    margin-bottom: 12px;
    font-size: 1.1rem;
}

.remoteReservationList .payment .addButton {
    background-color: #2dcd7a;
    color: #000000;
    border-radius: 10px;
    width: 20%;
    /* margin-left: -70%; */
    margin-bottom: 3%;
    text-transform: capitalize
}

/* booking details */
.remoteReservationList .bookingDetails {
    background-color: #ffffff;
    box-shadow: 5px 5px 5px -5px rgba(159, 159, 159, 255);
    margin-left: 5%;
}

.remoteReservationList .bookingDetails .bookingTypo {
    font-size: 1.5rem;
    text-align: left;
    padding: 4% 0 0 3%;
    margin-left: 2%;
}

.remoteReservationList .bookingDetails .bookingRow {
    display: flex;
    justify-content: space-between;
    padding: 1% 3%;
}

.remoteReservationList .bookingDetails .bookingRow div {
    display: flex;
    align-items: center;
    width: 50%;
}

.remoteReservationList .bookingDetails .bookingRow svg {
    margin-right: 5px;
    margin-left: 2%;
}

.remoteReservationList .bookingDetails .checkout {
    display: flex;
    justify-content: end;
}

.remoteReservationList .bookingDetails .bookingLabel {
    font-size: 1rem;
    text-align: left;
    margin-left: 2%;
}

.remoteReservationList .bookingDetails .bookingValue {
    font-size: 1rem;
    text-align: right;
    margin-right: 2%;
}

.remoteReservationList .payment .typo {
    font-size: 1.5rem;
}

.remoteReservationList .payment .paymentRadio {
    margin-left: 4%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.remoteReservationList .payment .paymentOptionWrapper {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-left: 4%;
    margin-bottom: 30px;
}

.remoteReservationList .payment .paymentOption {
    padding: 8px 10px;
    border: 1px solid #2dcd7a;
    margin-right: 10px;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
}

.remoteReservationList .payment .activePaymentOption {
    background-color: #2dcd7a;
    color: #fff;
}

.remoteReservationList .payment .cardDetailsBox {
    border-radius: 30px;
    margin: 2%;
}

.remoteReservationList .payment .cardDetailsBox .row {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 2%;
}

.remoteReservationList .payment .cardDetailsBox .row .typo {
    text-align: left;
    font-size: 1rem;
    padding-left: 0px !important;
    padding-top: 0px !important;
}

.remoteReservationList .payment .cardDetailsBox .resRight {
    margin-left: 2%;
    /* width: 100%; */
}

.remoteReservationList .payment .cardDetailsBox .expiryInputRow {
    display: flex;
    flex-direction: column;
    margin-left: 3%;
}

.remoteReservationList .payment .cardDetailsBox .expiryInputRow .typo {
    font-size: 1.1rem;
    margin-bottom: 12px;
    margin-left: 0px !important;
    padding-left: 0px !important;
    padding-top: 0px !important;
}

.remoteReservationList .payment .cardDetailsBox .expiryInputRow .expiryInput {
    width: 40%;
    margin-right: 1rem;
}

.remoteReservationList .payment .cardDetailsBox .expiryInputRow .expiryInputYY {
    width: 4rem;
}

.remoteReservationList .payment .cardDetailsBox .cvvInput {
    width: 20%;
    margin-right: 30%;
}

.remoteReservationList .payment .date {
    width: 49%;
}

@media screen and (max-width:1024px) {
    .remoteReservationList .main {
        display: block !important;
    }

    .remoteReservationList .main .leftRow {
        width: 97% !important;
    }

    .remoteReservationList .main .rightRow {
        width: 97% !important;
    }

    .remoteReservationList .addOnsForm {
        grid-template-columns: repeat(3, 1fr) !important
    }

}

.cvv {
    margin-left: 50px !important;
}

@media screen and (max-width:640px) {
    .remoteReservationList .userDetails .form-row {
        flex-direction: column !important;
        gap: 20px !important;
    }

    .remoteReservationList .box .reservationstypography {
        font-size: 24px !important;
    }

    .remoteReservationList .userDetails .form-row .field {
        width: 97% !important;
    }

    .remoteReservationList .userDetails .form-row-2 {
        width: 96% !important;
    }

    .remoteReservationList .userDetails .form-row-2 .full-width {
        width: 97% !important;
    }

    .remoteReservationList .userDetails .form-row-3 .right {
        width: 30% !important;
    }

    .remoteReservationList .cancel .backToSpace {
        display: none !important;
    }

    .cardNumberName {
        width: 95% !important;
        flex-direction: column !important;
        gap: 16px !important;
    }

    .cvv {
        margin-left: 3% !important;
    }

    .remoteReservationList .payment .cardDetailsBox .expiryInputRow .expiryInput {
        width: 75% !important;
    }

    .remoteReservationList .payment .cardDetailsBox .expiryInputRow .expiryInputYY {
        width: 75% !important;
    }

    .remoteReservationList .payment .cardDetailsBox .cvvInput {
        width: 75% !important;
    }

}